import {useEffect, useContext, useRef} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {useApolloClient} from '@apollo/client';
import result from 'lodash/result';

import {isPayUrl} from '@core/utils/url';
import startProfileCompletionListener from '@core/user/profile/menu/utils/startProfileCompletionListener';
import startDisapproveListeners from '@core/user/profile/utils/startDisapproveListeners';
import CURRENT_USER_ID_QUERY from '@core/user/profile/current/graphql/queries/currentUserId.gql';
import GLOBAL_FREE_MESSAGES_QUERY from '@core/messenger/common/graphql/queries/globalFreeMessages.gql';
import startChangeAdminListener from '@core/user/profile/utils/startChangeAdminListener';
import startPrimaryPhotoListener from '@core/user/photo/utils/startPrimaryPhotoListener';
import usePaymentStatus from '@core/payment/common/utils/usePaymentStatus';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import startUserPaymentProcessed from '@core/payment/paymentPending/utils/startUserPaymentProcessed';

import startExtraCoinsPopupListener from '@phoenix/coins/utils/startExtraCoinsPopupListener';
import startCreditsListeners from '@phoenix/credits/utils/startCreditsListeners';
import startFreeDailySpinListener from '@phoenix/freeDailySpin/utils/startFreeDailySpinListener';
import startFreeCoinsListener from '@phoenix/freeCoins/utils/startFreeCoinsListener';
import startCreditDebtListeners from '@phoenix/credits/utils/startCreditDebtListeners';
import startPersonalIdentityListeners from '@phoenix/personalIdentity/utils/startPersonalIdentityListeners';
import startStoriesListeners from '@phoenix/stories/utils/startStoriesListeners';
import startMediaUploadListeners from '@phoenix/mediaUpload/utils/startMediaUploadListeners';
import startMessengerListeners from '@phoenix/messenger/common/utils/startMessengerListeners';
import startUpdateGlobalMessageCountListener from '@phoenix/messenger/senderForm/utils/startUpdateGlobalMessageCountListener';
import startUpdateFreeMessageNotificationListener from '@phoenix/activity/utils/startUpdateFreeMessageNotificationListener';
import startResetGlobalFreeMessagesActiveRecipientListener from '@phoenix/messenger/senderForm/utils/startResetGlobalFreeMessagesActiveRecipientListener';
import startGlobalFreeMessagesPopupListeners from '@phoenix/messenger/senderForm/utils/startGlobalFreeMessagesPopupListeners';
import startBlockUserListeners from '@phoenix/user/profile/utils/startBlockUserListeners';
import startSearchCacheListener from '@phoenix/search/list/utils/startSearchCacheListener';
import startUpdatePaymentDataListener from '@phoenix/payment/utils/startUpdatePaymentDataListener';
import getTrustedStatusSubscription from '@phoenix/user/profile/current/utils/getTrustedStatusSubscription';
import startBlockStreamingListener from '@phoenix/streaming/utils/startBlockStreamingListener';
import PersonalIdentityService from '@phoenix/personalIdentity/PersonalIdentityService';
import SepaDebtorService from '@phoenix/sepaDebtor';
import startExtraEventListener from '@phoenix/extraEvent/utils/startExtraEventListener';
import startCoinsConfirmationPopupListener from '@phoenix/coins/utils/startCoinsConfirmationPopupListener';
import startCoinsTelegramBotSubscribePopupListener from '@phoenix/telegramBot/utils/startCoinsTelegramBotSubscribePopupListener';
import startOpenPPInNewTabListener from '@phoenix/payment/common/utils/startOpenPPInNewTabListener';
import startRequiredPhotoUploadListener from '@phoenix/mediaUpload/utils/startRequiredPhotoUploadListener';
import GLOBAL_FREE_MESSAGES_AVAILABLE_QUERY from '@phoenix/messenger/senderForm/graphql/queries/globalFreeMessagesAvailable.gql';
import startPersonalIdentityOnDemandListeners from '@phoenix/personalIdentityByDemand/utils/startPersonalIdentityOnDemandListeners';
import startWebPushListener from '@phoenix/webpush/utils/startWebPushListener';
import startLifetimeOfferListener from '@phoenix/lifetimeOffer/utils/startLifetimeOfferListener';
import startMicrofeatureAssignedListener from '@phoenix/microfeatures/common/utils/startMicrofeatureAssignedListener';
import startSpecialOfferPopupListener from '@phoenix/coins/specialOffer/utils/startSpecialOfferPopupListener';
import startShowCoinsBuyOfferBannerListener from '@phoenix/messenger/senderForm/utils/startShowCoinsBuyOfferBannerListener';
import useCoinsModel from '@phoenix/coins/utils/useCoinsModel';
import startTrustedVerificationPopupListener from '@phoenix/trusted/utils/startTrustedVerificationPopupListener';
import CoregistrationContext from '@phoenix/remarketing/coregistration/containers/CoregistrationContext';
import startRemarketingReloadListener from '@phoenix/remarketing/banner/utils/startRemarketingReloadListener';
import startRemarketingSettingsListener from '@phoenix/remarketing/offerPopup/utils/startRemarketingSettingsListener';
import startProgressiveWebApplicationListener from '@phoenix/progressiveWebApplication/utils/startProgressiveWebApplicationListener';
import PWA_SCENARIO_QUERY from '@phoenix/progressiveWebApplication/graphql/queries/pwaScenario.gql';
import startUpsellListener from '@phoenix/upsellPopup/utils/startUpsellListener';
import updateCachesAfterPayment from '@phoenix/payment/payProcess/utils/updateCachesAfterPayment';
import startFreeTrialSuccessPopupListener from '@phoenix/freeTrialSuccessPopup/utils/startFreeTrialSuccessPopupListener';

import startInAppLoginFragmentTokenListener from './startInAppLoginFragmentTokenListener';

/**
 * Starts all global subscriptions.
 */
const SubscriptionsStarter = () => {
  const client = useApolloClient();
  const {pathname} = useLocation();
  const history = useHistory();
  const {isFullyPaid, isPaid} = usePaymentStatus();
  const isPaymentUrl = isPayUrl(pathname);
  const {isCoinsModel} = useCoinsModel();
  const isPhone = usePhoneBreakpoint();
  const coregistrationContext = useContext(CoregistrationContext);
  const coregistrationContextRef = useRef(null);

  /**
   * Current useEffect doesn't depend on isPaymentUrl. It runs only one time. When user return from payment page to site,
   * isPaymentUrl will be changed and some listeners will start again.
   */
  useEffect(() => {
    client.query({query: CURRENT_USER_ID_QUERY}).then(({data}) => {
      const currentUserId = data.myUser.profile.id;

      startInAppLoginFragmentTokenListener(client, currentUserId);
      startExtraEventListener(client, currentUserId);

      if (isPhone) {
        startUpsellListener(client, currentUserId);
      }
    });
  }, [client, history, isPhone]);

  useEffect(() => {
    client
      // The request was made in appData - messenger.initialData.globalFreeMessages.available
      .query({query: GLOBAL_FREE_MESSAGES_AVAILABLE_QUERY})
      .then(({data}) => {
        startGlobalFreeMessagesPopupListeners(client);

        if (data?.messenger.initialData.globalFreeMessages.available) {
          client.query({query: GLOBAL_FREE_MESSAGES_QUERY}).then(() => {
            startUpdateGlobalMessageCountListener(client);
            startResetGlobalFreeMessagesActiveRecipientListener(client);
          });

          if (!isPaymentUrl) {
            startUpdateFreeMessageNotificationListener(client);
          }
        }
      });
  }, [client, isPaymentUrl]);

  useEffect(() => {
    if (isCoinsModel) {
      /**
       * Part of listeners should start on payment page too, for allowing
       * showing notifications or popups on payment page leave.
       */
      startFreeCoinsListener(client);
      startFreeDailySpinListener(client);
      startExtraCoinsPopupListener(client);
      startCreditsListeners(client);
      startCreditDebtListeners(client);
      startCoinsTelegramBotSubscribePopupListener(client);

      if (isPaymentUrl) {
        return;
      }

      startShowCoinsBuyOfferBannerListener(client);
      startCoinsConfirmationPopupListener(client);
    }
  }, [client, isCoinsModel, isPaymentUrl]);

  useEffect(() => {
    if (isPaymentUrl) return;

    coregistrationContextRef.current = coregistrationContext;

    startRemarketingReloadListener(client, coregistrationContextRef);
  }, [client, coregistrationContext, isPaymentUrl]);

  useEffect(() => {
    startRemarketingSettingsListener(client);
    startFreeTrialSuccessPopupListener(client);
  }, [client]);

  useEffect(() => {
    /**
     * Part of listeners should start on payment page too, for allowing
     * showing notifications or popups on payment page leave.
     */

    client.query({query: PWA_SCENARIO_QUERY}).then(({data}) => {
      if (data?.pwa?.scenario) {
        startProgressiveWebApplicationListener(client, data?.pwa?.scenario);
      }
    });

    startLifetimeOfferListener(client);
    startUserPaymentProcessed(client, updateCachesAfterPayment);

    /**
     * Note: personalIdentity.required and personalIdentity.availableOnDemand are not the same functionality!
     * required - Is personal identity verification required. Only for JPN (see: setPersonalIdentityOnUploadAllowed.yaml)
     * availableOnDemand - Is verification available on demand. For all locations except JPN.
     */
    startPersonalIdentityOnDemandListeners(client);

    PersonalIdentityService.getData().then(({data}) => {
      if (result(data, 'userFeatures.personalIdentity.required', false)) {
        // Prevent double open success popup
        !PersonalIdentityService.getViewPopupLaterFromStorage() &&
          PersonalIdentityService.showPopupIfAllowed();
        startPersonalIdentityListeners(client);
      }
    });

    if (isPaymentUrl) {
      return;
    }

    if (!isFullyPaid) {
      startOpenPPInNewTabListener(client);
    }

    if (isPhone) {
      startMicrofeatureAssignedListener(client);
    }

    startSpecialOfferPopupListener(client);
    startRequiredPhotoUploadListener(client, history);

    startProfileCompletionListener(client);

    startMediaUploadListeners(client);

    startMessengerListeners(client);
    startPrimaryPhotoListener(client, isCoinsModel);

    startBlockUserListeners(client);
    startChangeAdminListener(client);

    startSearchCacheListener(client);

    startUpdatePaymentDataListener(client);

    client.query({query: CURRENT_USER_ID_QUERY}).then(({data}) => {
      const currentUserId = data.myUser.profile.id;

      startStoriesListeners(client, currentUserId);
      startDisapproveListeners(client);

      if (isPaid && !isCoinsModel) {
        startTrustedVerificationPopupListener(client, currentUserId);
      }

      /**
       * Just starting subscription. Cause GQL automatically
       * updates cache due to type and fragment id
       */
      getTrustedStatusSubscription(client).subscribe();
      startBlockStreamingListener(client, currentUserId);

      startWebPushListener(client, currentUserId);
    });

    // Run SepaDebtorService only once
    SepaDebtorService.initialize();
  }, [
    client,
    history,
    isPaymentUrl,
    isPaid,
    isPhone,
    isFullyPaid,
    isCoinsModel,
  ]);

  return null;
};

export default SubscriptionsStarter;
